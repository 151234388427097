import React from "react";
import styled from "styled-components";
import bolt from "../../images/companies/bolt.png";
import inflection from "../../images/companies/inflection.jpeg";
import silisoul from "../../images/companies/silisoul.png";
import soroco from "../../images/companies/soroco.svg";
import i8labs from "../../images/companies/i8labs.png";
import qblocks from "../../images/companies/qblocks.jpeg";
import fimo from "../../images/companies/fimo.jpeg";

const workExperiences = [
    {
        companyName: "Inflection.io",
        url: "https://inflection.io",
        image: inflection,
        location: "Bangalore, India",
        position: "Software Development Engineer - II",
        startDate: "2024-07",
        endDate: "Present",
        dateRange: "02-2024 - Present",
    },
    {
        companyName: "Bolt.Earth",
        url: "https://bolt.earth",
        image: bolt,
        location: "Bangalore, India",
        position: "Software Development Engineer - II",
        startDate: "2023-04",
        endDate: "2024-02",
        dateRange: "04-2023 - 02-2024",
    },
    {
        companyName: "Bolt.earth",
        url: "https://bolt.earth",
        image: bolt,
        location: "Bangalore, India",
        position: "Software Development Engineer - I",
        startDate: "2022-04",
        endDate: "2023-03",
        dateRange: "04-2022 - 03-2023",
    },
    {
        companyName: "Soroco India",
        url: "https://soroco.com",
        image: soroco,
        location: "Bangalore, India",
        position: "Software Developer - Backend (Full Time)",
        startDate: "2021-05",
        endDate: "2022-04",
        dateRange: "07-2021 - 03-2022",
    },
    {
        companyName: "i8labs",
        url: "https://www.i8labs.io",
        image: i8labs,
        location: "Gurgaon, India (Remote)",
        position: "Software Developer - Full Stack (Intern)",
        startDate: "2021-03",
        endDate: "2021-07",
        dateRange: "03-2021 - 07-2021",
    },
    {
        companyName: "Qblocks",
        url: "https://www.qblocks.cloud",
        image: qblocks,
        location: "Bangalore, India",
        position: "Software Developer - Full Stack (Full Time)",
        startDate: "2020-11",
        endDate: "2021-04",
        dateRange: "11-2020 - 02-2021",
    },
    {
        companyName: "i8labs",
        url: "https://www.i8labs.io",
        image: i8labs,
        location: "Gurgaon, India (Remote)",
        position: "Software Developer - Full Stack (Intern)",
        startDate: "2020-08",
        endDate: "2020-11",
        dateRange: "08-2020 - 11-2020",
    },
    {
        companyName: "FIMO TECHNOLOGIES",
        url: "https://in.linkedin.com/company/fimofitness",
        image: fimo,
        location: "Hyderabad (Remote)",
        position: "Software Developer - Full Stack (Intern)",
        startDate: "2019-12",
        endDate: "2020-05",
        dateRange: "12-2019 - 05-2020",
    },
];

const WorkExperience = () => {
    return (
        <WorkExperienceContainer>
            {workExperiences.map((work, index) => {
                let startDate = work.startDate;
                let endDate = work.endDate;
                if (endDate === "Present") {
                    endDate = new Date();
                }
                let start = new Date(startDate);
                let end = new Date(endDate);
                let months =
                    (end.getFullYear() - start.getFullYear()) * 12 +
                    (end.getMonth() - start.getMonth() + 1);
                let years = Math.floor(months / 12);
                months = months % 12;
                return (
                    <WorkPosition key={index}>
                        <WorkClosure>
                            <CompanyLink href={work.url} target="_blank">
                                <img
                                    src={work.image}
                                    style={{
                                        width: "2rem",
                                        height: "2rem",
                                        marginRight: "8px",
                                        marginBottom: "-4px",
                                    }}
                                />
                                {work.companyName}
                            </CompanyLink>{" "}
                            - {work.location}
                        </WorkClosure>
                        <PositionTitle>{work.position}</PositionTitle>
                        <Date_p_tag>
                            {work.startDate} - {work.endDate} (
                            {years > 0 ? `${years} Years` : ""}
                            {years > 0 && months > 0 ? ", " : ""}
                            {months > 0 ? `${months} Months` : ""})
                        </Date_p_tag>
                    </WorkPosition>
                );
            })}
        </WorkExperienceContainer>
    );
};

const WorkExperienceContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    margin-left: 40%;
    @media (max-width: 768px) {
        margin-left: 10px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    @media (max-width: 480px) {
        margin-left: 10px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
`;

const WorkPosition = styled.div`
    margin-bottom: 40px;
`;

const WorkClosure = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 8px;
`;

const PositionTitle = styled.h3`
    font-size: 1.25rem;
    margin-bottom: 8px;
`;

const CompanyLink = styled.a`
    color: #007bff;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const Date_p_tag = styled.p`
    font-weight: bold;
    margin-bottom: 16px;
`;

export { WorkExperience, workExperiences };
