import React from "react";
import {
    Worker,
    Viewer,
    SpecialZoomLevel,
    ScrollMode,
    ViewMode,
} from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import styled from "styled-components";
import { Button } from "@mui/material";
import {
    EmailShareButton,
    WhatsappShareButton,
    EmailIcon,
    LinkedinIcon,
    WhatsappIcon,
} from "react-share";

function Resume() {
    const profileUrl = "https://satyamanand.in/resume";
    const portfolio =
        "https://raw.githubusercontent.com/SatyamAnand98/data/master/Satyam%20Anand%20Resume.pdf";

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const handleLinkedInShare = () => {
        const linkedInUrl = `https://www.linkedin.com/messaging/compose?body=${encodeURIComponent(
            profileUrl
        )}`;
        window.open(linkedInUrl, "_blank");
    };

    return (
        <ResumeStyled>
            <div className="pdf-container">
                <div className="downloadPdf">
                    <Button href={portfolio} variant="contained">
                        Download Resume
                    </Button>
                    <div className="share-container">
                        <WhatsappShareButton
                            url={profileUrl}
                            title="Check out this resume!"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <EmailShareButton
                            url={profileUrl}
                            subject="Resume"
                            body="Check out this resume at the following link:"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        <Button
                            className="linkedin-share"
                            onClick={handleLinkedInShare}
                        >
                            <LinkedinIcon size={32} round />
                        </Button>
                    </div>
                </div>
                <div className="pdf-viewer">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div className="toolbar-container">
                            <Toolbar />
                        </div>
                        <Viewer
                            fileUrl={portfolio}
                            plugins={[toolbarPluginInstance]}
                            enableSmoothScroll={true}
                            scrollMode={ScrollMode.Vertical}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            viewMode={ViewMode.SinglePage}
                        />
                    </Worker>
                </div>
            </div>
        </ResumeStyled>
    );
}

const ResumeStyled = styled.div`
    border-radius: 10px;
    padding: 10px;
    margin: 10px;

    .pdf-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .downloadPdf {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }

        .share-container {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            .linkedin-share {
                margin-left: -14px;
                margin-top: -6px;
            }
        }

        .pdf-viewer {
            width: 100%;
            height: 100%;
            border: 2px solid #ddd;
            border-radius: 8px;
            overflow: hidden;

            .toolbar-container {
                border-bottom: 1px solid #ddd;
                padding: 5px;
                background-color: rgb(164, 153, 153);
            }
        }
    }

    @media (max-width: 768px) {
        .pdf-viewer {
            max-width: 100%;
            border-radius: 5px;
        }
    }

    @media (max-width: 480px) {
        .pdf-viewer {
            max-width: 100%;
            border-radius: 5px;
        }
    }
`;

export default Resume;
