import styled from "styled-components";
import bg from "./images/profile/bg1.jpeg";
import { useMemo, useState, useEffect } from "react";
import { Orb } from "./Components/Orb/Orb.js";
import Navigation from "./Components/Navigation/Navigation.js";
import Footer from "./Components/Navigation/Footer.js";
import { useWindowSize } from "./utils/useWindowSize.js";
import GitHubRepos from "./Components/GitHub/Repos.js";
import Resume from "./Components/Resume/resume.js";
import About from "./Components/About/About.js";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Home from "./Components/Home/Home.js";
import { WorkExperience } from "./Components/Experience/Experience.js";
import { Blogs } from "./Components/Blogs/Blogs.js";
import { Routes, Route, useLocation } from "react-router-dom";
import { navigation } from "./utils/navigations.js";

export default function App() {
    const location = useLocation(); // React Router's location object
    const [active, setActive] = useState("");

    const { height } = useWindowSize();

    // Update active state when route changes
    useEffect(() => {
        const path = location.pathname.replace("/", "");
        const capitalizedPath = path.charAt(0).toUpperCase() + path.slice(1);
        setActive(capitalizedPath || "Home");
        window.scrollTo(0, 0); // Reset scroll to the top on route change
    }, [location]);

    const orbMemo = useMemo(() => {
        return <Orb />;
    }, []);

    return (
        <AppStyled>
            {orbMemo}
            <Navigation active={active} setActive={setActive} />
            <main className="main">
                <Routes>
                    <Route path={navigation[0].path} element={<Home />} />
                    <Route path={navigation[1].path} element={<About />} />
                    <Route
                        path={navigation[2].path}
                        element={
                            <GitHubRepos
                                username="SatyamAnand98"
                                maxPages={3}
                                hideForks={true}
                            />
                        }
                    />
                    <Route path={navigation[3].path} element={<Resume />} />
                    <Route path={navigation[4].path} element={<Blogs />} />
                    <Route
                        path={navigation[5].path}
                        element={<WorkExperience />}
                    />
                    <Route path="*" element={<Home />} />
                </Routes>
            </main>
            <Footer />
            <Analytics />
            <SpeedInsights />
        </AppStyled>
    );
}

const AppStyled = styled.div`
    min-height: 100vh; /* Ensure the app fills the viewport */
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;

    .main {
        flex: 1; /* Take up remaining space between Navigation and Footer */
        background: rgba(40, 36, 41, 0.07);
        border: 3px solid #282429;
        border-radius: 32px;
        margin-right: 10px;
        margin-left: 10px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @keyframes burst {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @media (max-width: 768px) {
        .main {
            border-radius: 16px;
        }
    }

    @media (max-width: 480px) {
        .main {
            border-radius: 8px;
        }
    }
`;
