import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
    }

    :root {
        --primary-color: #222260;
        --primary-color2: rgba(34, 34, 96, 0.6);
        --primary-color3: rgba(34, 34, 96, 0.4);
        --color-green: #42AD00;
        --color-grey: #aaa;
        --color-accent: #F56692;
        --color-delete: #FF0000;
        --color-red: #FF0000;
        --color-blue: #00BFFF;
        --color-lightblue: #ADD8E6;
        --color-yellow: #FFFF00;
        --color-orange: #FFA500;
        --color-purple: #800080;
        --color-pink: #FFC0CB;
        --color-brown: #A52A2A;
        --color-white: #FFFFFF;
        --color-black: #000000;
        --color-dark: #222260;
        --color-brown-contrast: #A52A2A;
        --color-light: #F2F2F2;
        --color-dark-contrast: #222260;
        --color-footer: #3b3838;
    }

    html, body {
        height: 100%;
        font-size: 16px; /* Base font size for rem units */
    }

    body {
        font-family: 'Nunito', Arial, sans-serif;
        font-size: clamp(1rem, 1.5vw, 1.2rem);
        overflow-y: auto;
        overflow-x: hidden;
        color: var(--primary-color2);
    }

    h1, h2, h3, h4, h5, h6 {
        color: var(--color-light);
    }

    p, span {
        color: var(--color-light);
    }

    .error {
        color: var(--color-red);
        animation: shake 0.5s ease-in-out;
        @keyframes shake {
            0% { transform: translateX(0); }
            25% { transform: translateX(10px); }
            50% { transform: translateX(-10px); }
            75% { transform: translateX(10px); }
            100% { transform: translateX(0); }
        }
    }

    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        // background-color: var(--primary-color);
        width: 0px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        // background-color: var(--color-grey);
    }
`;
